import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { data } from "../pages/Products/config/data";
import { toast } from "react-hot-toast";
import {
    data as productData,
    gtinData,
} from "../pages/ProductDetail/config/data";
import { data as GTINDATA, batchData } from "../pages/GtinDetail/config/data";
import {
    DATA,
    BATCH_OMIT_DATA,
    BUTTONS,
} from "../pages/BatchDetail/config/data";
import { humanize } from "uno-js";
import moment from "moment";
import { GET_PRODUCTS } from "../pages/Products/graphql/getProducts.query";
import { GET_PRODUCT } from "../pages/ProductDetail/graphql/getProduct.query";
import { GET_GTIN_DETAIL } from "../pages/GtinDetail/graphql/getGtinDetail.query";
import { GET_BATCH_DETAIL } from "../pages/BatchDetail/graphql/getBatchDetail.query";
import { UPDATE_BATCH } from "../pages/BatchDetail/graphql/updateBatch.mutation";
import { UPDATE_PRODUCT } from "../pages/ProductDetail/graphql/updateProduct.mutation";
import { UPDATE_GTIN_DETAIL } from "../pages/GtinDetail/graphql/updateGtinDetail.mutation";
import { NO_IMAGE, PLAY_BTN } from "../assets/images";
import {getErrorMessage} from "../utils/error.message";
import { UPLOAD_FILE } from "../pages/ProductDetail/graphql/upload-image.mutation";

const SERVICE_NAME = "analytics.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class ProductsService {
    name = SERVICE_NAME;
    private static instance: ProductsService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ProductsService();
        }
        return this.instance;
    }

    _getProducts = (data: any) => {
        let _tempData =
            data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                let x = Object.entries(item);
                let meta: any = [];
                x &&
                    Array.isArray(x) &&
                    x.map((ele, eleIndex) => {
                        if (
                            ele[0] !== "gtins" &&
                            ele[0] !== "assets" &&
                            ele[0] !== "_id" &&
                            ele[0] !== "description" &&
                            ele[0] !== "__typename" &&
                            ele[0] &&
                            ele[1]
                        ) {
                            meta.push({
                                key: humanize(ele[0]).toString(),
                                value: ele[1],
                            });
                        }
                    });
                let variants: any = [];
                if (
                    item?.images &&
                    Array.isArray(item?.images) &&
                    item?.images.length > 0
                ) {
                    item?.images.map((ele: any, eleIndex: number) => {
                        variants.push({
                            images: ele,
                        });
                    });
                }
                return {
                    item: {
                        idx: item?._id,
                        productId: item?._id,
                        name: item?.name,
                        title: item?.name,
                        variants: variants,
                        img:
                            item?.images &&
                            Array.isArray(item?.images) &&
                            item?.images.length > 0
                                ? item?.images[0]
                                : "",
                        category: "Bronze",
                        description: item?.description,
                        gtins: item?.gtins,
                        meta: meta,
                    },
                };
            });
        log.info(`_tempData :`, _tempData);
        return _tempData;
    };

    async getProducts() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res) {
                    let _data = res?.data?.getProducts?.data;
                    let results = _data.map((item: any, index: number) => {
                        return {
                            ...item,
                            img:
                                item?.images &&
                                Array.isArray(item?.images) &&
                                item?.images.length > 0
                                    ? item?.images[0]
                                    : "",
                            idx: item?._id,
                            productId: item?._id,
                            name: item?.name,
                            title: item?.name,
                            //subtitle: item?.description
                        };
                    });

                    return {
                        pagination: {
                            nextOffset: 100,
                            totalItems: res?.data?.getProducts?.data?.length,
                        },
                        results,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getGtins = (data: any, image: string) => {
        let _tempData =
            data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                return {
                    item: {
                        idx: item?._id,
                        gtinId: item?._id,
                        name: item?.gtinKey,
                        title: item?.gtinKey,
                        img: image,
                        category: "Bronze",
                    },
                };
            });
        return _tempData;
    };

    _getProductsDetails = (data: any) => {
        let x = Object.entries(data);
        let y = data?.attrs ? data?.attrs : [];
        let meta: any = [];
        x &&
            Array.isArray(x) &&
            x.map((ele: any, eleIndex: number) => {
                if (ele[0] && ele[1] && ele[0] === "updatedAt") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "Updated At",
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                }
                // else  if (ele[0] && ele[1] && ele[0] === "createdAt") {
                //     meta.push({
                //         //key: humanize(ele[0]).toString(),
                //         key: "Updated At",
                //         value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                //     });
                // }
                else if (
                    ele[0] !== "gtins" &&
                    ele[0].toLowerCase() !== "lname" &&
                    ele[0] !== "images" &&
                    ele[0] !== "_id" &&
                    ele[0] !== "description" &&
                    ele[0] !== "__typename" &&
                    ele[0].toLowerCase() !== "updatedAt" &&
                    ele[0] !== "createdAt" &&
                    ele[0] &&
                    typeof ele[0] === "string" &&
                    ele[1] &&
                    typeof ele[1] === "string"
                ) {
                    meta.push({
                        key: humanize(ele[0]).toString(),
                        value: ele[1],
                    });
                }
            });

        y &&
            Array.isArray(y) &&
            y.map((ele: any, eleIndex: number) => {
                if (!!ele?.val && ele?.val !== " ")
                    meta.push({
                        key: humanize(ele?.name).toString(),
                        value: ele?.val,
                    });
            });
        let variants: any = [];
        let videos: any = [];
        if (
            data?.images &&
            Array.isArray(data?.images) &&
            data?.images.length > 0
        ) {
            data?.images.map((ele: any, eleIndex: number) => {
                variants.push({
                    original: ele,
                    thumbnail: ele,
                    thumbnailHeight: "100",
                    thumbnailClass: "image-thumb",
                });
            });
        }

        data?.assets?.videos &&
      Array.isArray(data?.assets?.videos) &&
      data?.assets?.videos.forEach((item: any, index: number) => {
        if (item?.src)
        variants.push({
            original: PLAY_BTN,
            thumbnail: PLAY_BTN,
            thumbnailHeight: "100",
            thumbnailClass: "image-thumb",
            type: "video",
            embedUrl: `${item?.src}${"#t=0.4"}`,
          });
      });
        if (
            data?.assets?.videos &&
            Array.isArray(data?.assets?.videos) &&
            data?.assets?.videos.length > 0
        ) {
            data?.assets?.videos.map((ele: any, eleIndex: number) => {
                videos.push(ele?.src);
            });
        }
        return {
            name: data?.name,
            title: data?.name,
            subtitle: data?.description,
            description: data?.description,
            images: variants,
            videos: videos,
            img:
                data?.images &&
                Array.isArray(data?.images) &&
                data?.images.length > 0
                    ? data?.images[0]
                    : "",
            category: "Bronze",
            gtins: this._getGtins(data?.gtins, NO_IMAGE),
            meta: meta,
            actions: [
                {
                    key: "edit",
                    title: "Edit Product",
                },
                // {
                //     key: "add",
                //     title: "Add GTIN",
                // },
            ],
        };
    };

    async getProductDetail(productId: string) {
        log.info(`in product- detail service`, productId);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCT,
                    variables: {
                        productId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProduct) {
                    let _data = res?.data?.getProduct?.gtins;
                    let results = _data.map((item: any, index: number) => {
                        return {
                            ...item,
                            img:
                                item?.images &&
                                Array.isArray(item?.images) &&
                                item?.images.length > 0
                                    ? item?.images[0]
                                    : "",
                            idx: item?._id,
                            productId: item?._id,
                            name: item?.name,
                            title: item?.name,
                        };
                    });
                    return {
                        productDetails: this._getProductsDetails(
                            res?.data?.getProduct
                        ),
                        results,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getBatches = (data: any, image: string) => {
        let _tempData =
            data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                return {
                    item: {
                        idx: item?._id,
                        batchId: item?._id,
                        name: item?.name,
                        title: item?.name,
                        img: image,
                        category: "Bronze",
                    },
                };
            });
        return _tempData;
    };

    _getGtinDetails = (data: any) => {
        console.log(`data: `, data);
        let x = Object.entries(data);
        let y = data?.attrs ? data?.attrs : [];
        let meta: any = [];
        console.log(`x: `, x);
        y &&
            Array.isArray(y) &&
            y.map((ele: any, eleIndex: number) => {
                if (ele?.name !== "gtinKey" && ele["name"] && ele["val"]) {
                    meta.push({
                        key: humanize(ele?.name).toString(),
                        value: ele?.val,
                    });
                }
            });

        x &&
            Array.isArray(x) &&
            x.forEach((ele, eleIndex) => {
                console.log(`element ::`, ele);
                if (ele[0] && ele[1] && ele[0] === "updatedAt") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "Updated At",
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                } else if (ele[0] && ele[1] && ele[0] === "createdAt") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "Created At",
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                }  else if (ele[0] && ele[1] && ele[0] === "gtinKey") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "GTIN key",
                        value: ele[1],
                    });
                } else if (
                    ele[0] !== "_id" &&
                    ele[0] !== "description" &&
                    ele[0] !== "__typename" &&
                    ele[0] !== "batches" &&
                    ele[0] !== "item" &&
                    ele[0] !== "product" &&
                    ele[0] !== "price" &&
                    ele[0] !== "updatedAt" &&
                    ele[0] !== "createdAt" &&
                    ele[0] !== "quantity" &&
                    ele[0] !== "gtinKey" &&
                    ele[0] &&
                    ele[0] !== "" &&
                    typeof ele[0] === "string" &&
                    ele[1] &&
                    ele[1] !== "" &&
                    typeof ele[1] === "string"
                ) {
                    meta.push({
                        key: humanize(ele[0]).toString(),
                        value: ele[1],
                    });
                }
            });

        console.log(`meta: `, meta);
        let variants: any = [];
        if (
            data?.assets &&
            data?.assets?.imgs &&
            Array.isArray(data?.assets?.imgs) &&
            data?.assets?.imgs.length > 0
        ) {
            variants.push({
                original: data?.assets?.imgs[0]?.src,
                thumbnail: data?.assets?.imgs[0]?.src,
                thumbnailHeight: "100",
                thumbnailClass: "image-thumb",
            });
        } else {
            variants.push({
                original: NO_IMAGE,
                thumbnail: NO_IMAGE,
                thumbnailHeight: "100",
                thumbnailClass: "image-thumb",
            });
        }
        return {
            gtinId: data?._id,
            gtinKey: data?.gtinKey,
            name: data?.name,
            title: data?.name,
            subtitle:
                data?.desc && Array.isArray(data?.desc) && data?.desc.length > 0
                    ? data?.desc[0]?.val
                    : "",
            description:
                data?.desc && Array.isArray(data?.desc) && data?.desc.length > 0
                    ? data?.desc[0]?.val
                    : "",
            images: variants,
            category: "Bronze",
            batches: this._getBatches(data?.batches, NO_IMAGE),
            meta: meta,
            price: data?.price,
            actions: [
                {
                    key: "edit",
                    title: "Edit Variant Details",
                },
                // {
                //     key: "associateBatch",
                //     title: "Associate More Batch",
                // },
            ],
        };
    };

    async getGtinDetail(gtinId: string) {
        log.info(`in gtin- detail service`, gtinId);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTIN_DETAIL,
                    variables: {
                        gtinId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtin)
                    return {
                        gtinDetails: this._getGtinDetails(res?.data?.getGtin),
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getBatchDetails = (data: any) => {
        const _formattedData = _.omit(data, BATCH_OMIT_DATA ?? []);
        let x = Object.entries(_formattedData);
        let meta: any = [];
        console.log(`x: `, x);
        x &&
            Array.isArray(x) &&
            x.map((ele, eleIndex) => {
                if (ele[0] && ele[1] && ele[0] === "manufactureDate") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "Valid From",
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                } else if (ele[0] && ele[1] && ele[0] === "shelfLife") {
                    meta.push({
                        //key: humanize(ele[0]).toString(),
                        key: "Valid Until",
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                } else if (ele[0] && ele[1] && ele[0] === "updatedAt") {
                    meta.push({
                        key: humanize(ele[0]).toString(),
                        value: moment(ele[1]).format("DD-MM-yyyy HH:mm:ss"),
                    });
                } else if (ele[0] && ele[1]) {
                    meta.push({
                        key: humanize(ele[0]).toString(),
                        value: ele[1],
                    });
                }
            });
        console.log(`meta: `, meta);
        let variants: any = [];
        if (
            data?.assets &&
            data?.assets?.imgs &&
            Array.isArray(data?.assets?.imgs) &&
            data?.assets?.imgs.length > 0
        ) {
            variants.push({
                original: data?.assets?.imgs[0]?.src,
                thumbnail: data?.assets?.imgs[0]?.src,
                thumbnailHeight: "100",
                thumbnailClass: "image-thumb",
            });
        } else {
            variants.push({
                original: NO_IMAGE,
                thumbnail: NO_IMAGE,
                thumbnailHeight: "100",
                thumbnailClass: "image-thumb",
            });
        }

        return {
            name: data?.name,
            title: `Product: ${data?.product?.name}  :: Batch: ${data?.name}`,
            subtitle: "",
            images: variants,
            img: NO_IMAGE,
            category: "Bronze",
            description: data?.description,
            manufactureDate: moment(data?.manufactureDate).format("yyyy-MM-DD"),
            shelfLife: moment(data?.shelfLife).format("yyyy-MM-DD"),
            promoVideoUrl: data?.promoVideoUrl,
            promoWebsiteUrl: data?.promoWebsiteUrl,
            promoButtonText1: data?.promoButtonText1,
            promoButtonText2: data?.promoButtonText2,
            meta: meta,
            actions: [
                ...BUTTONS,
                {
                    key: "addPromo",
                    title: "Add/Update Promo Video",
                },
            ],
        };
    };

    async getBatchDetail(batchId: string) {
        log.info(`in batch- detail service`, batchId);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_BATCH_DETAIL,
                    variables: {
                        batchId,
                    },
                    fetchPolicy: "network-only",
                });

                if (res?.data?.getBatch)
                    return {
                        batchDetails: this._getBatchDetails(
                            res?.data?.getBatch
                        ),
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateBatch(payload: any) {
        log.info(`in update batch service`, data);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: UPDATE_BATCH,
                    variables: {
                        batchId: payload?.id,
                        data: {
                            ...payload?.data,
                        },
                    },
                    fetchPolicy: "no-cache",
                });

                if (res?.data?.updateBatch) {
                    return res?.data?.updateBatch;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateProductDetail(payload: any) {
        log.info(`in update product service`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: UPDATE_PRODUCT,
                    variables: {
                        productId: payload?.productId,
                        data: {
                            ...payload?.data,
                            //brand: "614adb9996cce558bc4a6e1b"
                        },
                    },
                    fetchPolicy: "no-cache",
                });

                if (res?.data?.updateProduct) {
                    return res?.data?.updateProduct;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateGtinDetail(payload: any) {
        log.info(`in update gtin service`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: UPDATE_GTIN_DETAIL,
                    variables: {
                        gtinId: payload?.gtinId,
                        data: {
                            ...payload?.data,
                            //item: "618236c67493694eac042b6f"
                        },
                    },
                    fetchPolicy: "no-cache",
                });

                if (res?.data?.updateGtin) {
                    return res?.data?.updateGtin;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadFile(payload: any) {
        log.info(`payload in uploadFiel service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_FILE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.uploadFile) {
                    return { image: res?.data?.uploadFile };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    // async updateBatchDetails(payload: any) {
    //     log.info(`in update gtin service`, payload);
    //     const graphqlModule = getGraphQLModule();
    //     const client = graphqlModule.getClient("graphql-gateway");
    //     try {
    //         if (client) {
    //             const res = await client.query({
    //                 query: UPDATE_GTIN_DETAIL,
    //                 variables: {
    //                     gtinId: payload?.gtinId,
    //                     data: {
    //                         ...payload?.data
    //                     },

    //                 },
    //                 fetchPolicy: "no-cache",
    //             });

    //             if (res?.data?.updateGtin) {
    //                 return res?.data?.updateGtin;
    //             }
    //         }
    //     } catch (err: any) {
    //         err?.graphQLErrors &&
    //             Array.isArray(err?.graphQLErrors) &&
    //             err?.graphQLErrors.length > 0 &&
    //             toast.error(err?.graphQLErrors[0]?.message);
    //     }
    // }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
