export const associate_campaign_form = [
    {
        label: "Associate",
        name: "associate",
        type: "dynamic-select-field",
        fieldProps: {},
    },
];

export const campaign_edit_form = [
    {
        type: "html",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Content",
                name: "content",
                type: "ck-editor",
                fieldProps: {},
            },
            {
                label: "Upload Cover images ",
                name: "coverImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "video",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Video URL*",
                name: "videoUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload Cover images ",
                name: "coverImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "lottie",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Lottie Url*",
                name: "lottieUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload Cover images ",
                name: "coverImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },

            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "still-picture",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload image",
                name: "stillImages",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 5,
                fieldProps: {},
            },
            {
                label: "Upload Cover images ",
                name: "coverImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "voucher",
        form: [
            {
                label: "Title*",
                name: "primaryTitle",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Subtitle*",
                name: "secondaryTitle",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload images ",
                name: "voucherImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },
            {
                label: "Upload Cover images ",
                name: "coverImage",
                type: "image-dropzone",
                required: true,
                values: [],
                maxFiles: 1,
                fieldProps: {},
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Text*",
                name: "footerText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Sub Text*",
                name: "footerSubText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Background Color",
                name: "footerBackgroundColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
];

export const html_form_fields = {
    title: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
        },
    },
};

export const stillpicture_form_fields = {
    primaryText: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
            regexp: {
                regexp: "^(.{1,20})$",
                message: "Enter title with max 20 charaters",
            },
        },
    },
};

export const voucher_form_fields = {
    title: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
        },
    },
    coverImage: {
        validators: {
            notEmpty: {
                message: "Cotent is required",
            },
        },
    },
};
