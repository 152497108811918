export const data = [
  {
    item: {
      idx: 1,
      name: "Mcdowell's No.1 Reserve Whisky Original",
      img:
        "https://s3.ap-south-1.amazonaws.com/io.trag-vlinder.diageo-demo/product_image_1.png",
      category: "Bronze",
    },
  },
  {
    item: {
      idx: 2,
      name: "Product 2",
      img: "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/product.png",
      category: "Bronze",
    },
  },
  {
    item: {
      idx: 3,
      name: "Amrut MaQintosh",
      img: "https://i.ibb.co/D1JQpQY/product-image.png",
      category: "Bronze",
    },
  },
];
