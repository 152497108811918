import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { GRID_ITEMS } from "../pages/Analytics/config/data";
import { toast } from "react-hot-toast";
import { GET_PRODUCTS } from "../pages/BatchLevelQR/graphql/getProducts.query";
import { GET_GTINS } from "../pages/BatchLevelQR/graphql/getGtins.query";
import { CREATE_BATCH_QR } from "../pages/BatchLevelQR/graphql/createBatchQR.mutation";
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "batch-level-qr.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class BatchLevelQRService {
    name = SERVICE_NAME;
    private static instance: BatchLevelQRService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new BatchLevelQRService();
        }
        return this.instance;
    }

    _getProducts = (data: any) => {
        let _products: any[] = [];
        let _qrBatches: any[] = [];
        _products =
            data?.getProducts?.data &&
            Array.isArray(data?.getProducts?.data) &&
            data?.getProducts?.data.length > 0 &&
            data?.getProducts?.data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        console.log(`_products :`, _products);
        return _products;
    };

    async getProducts() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProducts)
                    return {
                        products: this._getProducts(res?.data),
                    };
            }
        } catch (err: any) {
           getErrorMessage(err);
        }
    }

    _getGtins = (data: any) => {
        let _gtins: any[] = [];
        _gtins =
            data?.getGtinsByProductID &&
            Array.isArray(data?.getGtinsByProductID) &&
            data?.getGtinsByProductID.length > 0 &&
            data?.getGtinsByProductID.map((item: any, index: number) => {
                return {
                    label: item?.gtinKey,
                    value: item?._id,
                };
            });
        console.log(`_gtins :`, _gtins);
        return _gtins;
    }

    async getGtins(productId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return { gtins: this._getGtins(res?.data)};
                }
            }
        } catch (err: any) {
           getErrorMessage(err);
        }
    }

    async createBatchQR() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_BATCH_QR,
                    variables: {},
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.createBatchLazyBindingParent) {
                    return res?.data?.createBatchLazyBindingParent;
                }
            }
        } catch (err: any) {
           getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
