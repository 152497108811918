import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from 'react-hot-toast';
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "assets.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class AssetsService {
    name = SERVICE_NAME;
    private static instance: AssetsService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new AssetsService();
        }
        return this.instance;
    }

    async getNewAssets() {
        const graphqlModule = getGraphQLModule();
        //const client = graphqlModule.getClient("graphql-gateway");
        try {
            // if (client) {
            //     // const res = await client.query({
            //     //     query: GET_ANALYTICS,
            //     //     variables: {},
            //     //     fetchPolicy: "network-only",
            //     // });
            // }
            return true;
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
