import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { GET_CLUB_REGISTRATION_TABLE } from "../pages/ClubRegistration/graphql/getClubRegistrationTable.query";
import moment from "moment";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "analytics.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class ClubRegistrationService {
    name = SERVICE_NAME;
    private static instance: ClubRegistrationService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ClubRegistrationService();
        }
        return this.instance;
    }

    async getClubRegistrationTable(limit: number, skip: number, filter: any) {
        log.info(`in transaction table service:`, limit, skip);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.date ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: GET_CLUB_REGISTRATION_TABLE,
                    variables: {
                        criteria: {
                            city: filter?.city ?? null,
                            country: filter?.country ?? null,
                            email: filter?.email ?? null,
                            endDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[1] !== ""
                                    ? endDate
                                    : null,
                            region: filter?.region ?? null,
                            startDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[0] !== ""
                                    ? startDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                        limit: limit,
                        skip: skip,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.clubRegistrationTable)
                    return res?.data?.clubRegistrationTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
