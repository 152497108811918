export const FIELDS: any = {
    productId: {
        validators: {
            notEmpty: {
                message: "Product is required"
            },

        }
    },
    gtinId: {
        validators: {
            notEmpty: {
                message: "GTIN is required"
            },

        }
    },
    batchId: {
        validators: {
            notEmpty: {
                message: "Excise batch number is required"
            },

        }
    },
}

export const FORM_FIELDS = [
    {
        label: "Select Product",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        }
    },
    {
        label: "Select GTIN",
        name: "gtinId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: []
        }
    },
    {
        label: "Select Excise Batch",
        name: "batchId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: []
        }
    },
]
