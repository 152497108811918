import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { tableData } from "../pages/Campaigns/config/table";
import { toast } from "react-hot-toast";
import { GET_CAMPAIGNS } from "../pages/Campaigns/graphql/getCampaigns.query";
import { getErrorMessage } from "../utils/error.message";
import { GET_CAMPAIGN_TEMPLATE } from "../pages/NewCampaigns/graphql/get-template.query";
import { CREATE_CAMPAIGN } from "../pages/NewCampaigns/graphql/create-campaign.mutation";
import { UPDATE_CAMPAIGN } from "../pages/Campaigns/graphql/updateCampaign.mutation";
import { ASSOCIATE_CAMPAIGN } from "../pages/Campaigns/graphql/associateCampaign.mutation";
import { UPDATE_CAMPAIGN_STATUS } from "../pages/Campaigns/graphql/updateCampaignStatus.mutation";
import { GET_CAMPAIGN_DETAIL } from "../pages/Campaigns/graphql/getCampaignDetails";
import { UPLOAD_FILE } from "../pages/NewCampaigns/graphql/upload-image.mutation";
import {
    campaign_form,
    campaign_form_fields,
} from "../pages/NewCampaigns/config/formdata";
import { capitalCase } from "change-case";
import { associate_campaign_form } from "../pages/Campaigns/config/formdata";

const SERVICE_NAME = "campaign.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class CampaignService {
    name = SERVICE_NAME;
    private static instance: CampaignService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new CampaignService();
        }
        return this.instance;
    }

    _getAssocaiteFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        if (_data) {
            let formFields =
                associate_campaign_form &&
                associate_campaign_form.map((item: any, index: number) => {
                    if (item?.name === "associate") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                productOptions: _data,
                                products: data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    async getCampaign(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_CAMPAIGNS,
                    variables: {
                        limit: limit,
                        skip: skip,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.campaignTable) {
                    return {
                        campaignTableData: res?.data?.campaignTable,
                        form: this._getAssocaiteFormFields(
                            res?.data?.getProducts?.data
                        ),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getForm(data: any) {
        let _res: any[] = [];
        log.blue(`_getForm in service :`, data);
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                _res.push({
                    label: item?.type && capitalCase(item?.type),
                    value: item?.type,
                    data: item?.typeProps,
                    id: item?._id,
                });
            });
        let _form =
            campaign_form &&
            campaign_form.map((item, index) => {
                if (item?.name === "template") {
                    return {
                        ...item,
                        fieldProps: {
                            ...item?.fieldProps,
                            initialOptions: _res,
                        },
                    };
                } else {
                    return {
                        ...item,
                    };
                }
            });
        return _form;
    }

    async getCampaignTemplate() {
        log.blue(`in get template service ::`);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_CAMPAIGN_TEMPLATE,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getCampaignTemplates)
                    return {
                        templates: res?.data?.getCampaignTemplates,
                        form: this._getForm(res?.data?.getCampaignTemplates),
                        formField: campaign_form_fields,
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getTypeProps(formData: any, templateData: any) {
        let _tempData: any = { ...templateData?.data };
        if (templateData?.template === "voucher") {
            _tempData = {
                ..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.voucherImage,
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    primaryTitle: formData?.primaryTitle,
                    secondaryTitle: formData?.secondaryTitle,
                },
                footer: {
                    ..._tempData?.footer,
                    backgroundColor: formData?.footerBackgroundColor,
                    smallText: formData?.footerSubText,
                    text: formData?.footerText,
                },
            };

            return _tempData;
        } else if (templateData?.template === "still-picture") {
            _tempData = {
                ..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.stillImages,
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
            };

            return _tempData;
        } else if (templateData?.template === "lottie") {
            _tempData = {
                ..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.lottieUrl ? [formData?.lottieUrl] : [],
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
            };

            return _tempData;
        } else if (templateData?.template === "video") {
            _tempData = {
                ..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.videoUrl ? [formData?.videoUrl] : [],
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
            };

            return _tempData;
        } else if (templateData?.template === "html") {
            _tempData = {
                ..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    content: formData?.content ? formData?.content : "",
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
            };

            return _tempData;
        }
    }

    async createCampaign(payload: any) {
        log.blue(`in createCampaign service ::`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");

        let _res = this._getTypeProps(payload?.formData, payload?.template);
        log.blue(`in createCampaign service after formatting ::`, _res);
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_CAMPAIGN,
                    variables: {
                        status: "created",
                        name: payload?.template?.campaignName,
                        campaignTemplateId: payload?.template?.templateId,
                        description: "test",
                        data: [
                            {
                                type: payload?.template?.template,
                                typeProps: _res,
                            },
                        ],
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.createCampaign)
                    return { ...res?.data?.createCampaign };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    __getTypeProps(formData: any, templateData: any) {
        let _tempData: any = { ...templateData?.typeProps };
        if (templateData?.type === "voucher") {
            _tempData = {
                //..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.voucherImage,
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    primaryTitle: formData?.primaryTitle,
                    secondaryTitle: formData?.secondaryTitle,
                },
                footer: {
                    ..._tempData?.footer,
                    text: formData?.footerText ?? "",
                    smallText: formData?.footerSubText ?? "",
                    backgroundColor: formData?.footerBackgroundColor ?? "",
                },
            };
            delete _tempData?.button?._id;
            delete _tempData?.comp?._id;
            delete _tempData?.message?._id;
            delete _tempData?.title?._id;
            delete _tempData?.footer?._id;
            delete _tempData?.title?.textStyle?._id;
            return _tempData;
        } else if (templateData?.type === "still-picture") {
            _tempData = {
                //..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: formData?.stillImages,
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
                footer: null,
            };
            delete _tempData?.button?._id;
            delete _tempData?.comp?._id;
            delete _tempData?.message?._id;
            delete _tempData?.title?._id;
            delete _tempData?.footer?._id;
            delete _tempData?.title?.textStyle?._id;
            return _tempData;
        } else if (templateData?.type === "lottie") {
            _tempData = {
                //..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: [formData?.lottieUrl],
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
                footer: null,
            };
            delete _tempData?.button?._id;
            delete _tempData?.comp?._id;
            delete _tempData?.message?._id;
            delete _tempData?.title?._id;
            delete _tempData?.footer?._id;
            delete _tempData?.title?.textStyle?._id;
            return _tempData;
        } else if (templateData?.type === "video") {
            _tempData = {
                //..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    uri: [formData?.videoUrl],
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
                footer: null,
            };
            delete _tempData?.button?._id;
            delete _tempData?.comp?._id;
            delete _tempData?.message?._id;
            delete _tempData?.title?._id;
            delete _tempData?.footer?._id;
            delete _tempData?.title?.textStyle?._id;
            return _tempData;
        } else if (templateData?.type === "html") {
            _tempData = {
                //..._tempData,
                button: {
                    ..._tempData?.button,
                    buttonColor: formData?.buttonColor,
                    shadowColor: formData?.buttonColor,
                    text: formData?.buttonText,
                    uri: formData?.buttonUrl,
                    titleColor: formData?.buttonTitleColor,
                },
                comp: {
                    ..._tempData?.comp,
                    content: formData?.content,
                    posterUri:
                        formData?.coverImage &&
                        Array.isArray(formData?.coverImage) &&
                        formData?.coverImage.length > 0
                            ? formData?.coverImage[0]
                            : "",
                },
                message: {
                    ..._tempData?.message,
                    text: formData?.messageText,
                },
                title: {
                    ..._tempData?.title,
                    text: formData?.primaryText,
                },
                footer: null,
            };
            delete _tempData?.button?._id;
            delete _tempData?.comp?._id;
            delete _tempData?.message?._id;
            delete _tempData?.title?._id;
            delete _tempData?.title?.textStyle?._id;
            delete _tempData?.footer?._id;
            return _tempData;
        }
    }

    async updateCampaign(payload: any) {
        log.blue(`in createCampaign service ::`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");

        let _data = this.__getTypeProps(payload?.formData, payload?.screen);
        log.blue(`in createCampaign service after formatting ::`, _data);
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPDATE_CAMPAIGN,
                    variables: {
                        campaignId: payload?.screen?.tempId,
                        //description: "",
                        data: [
                            {
                                type: payload?.screen?.type,
                                typeProps: _data,
                            },
                        ],
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.editCampaign)
                    return { ...res?.data?.editCampaign };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getAssociateData(data: any) {
        let _data: any[] = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.forEach((item: any, index: number) => {
                _data.push({
                    item: item?.product?.value,
                    variant: item?.gtin?.value,
                    batch: item?.batch?.value,
                });
            });

        return _data;
    }

    async associateCampaign(payload: any) {
        log.blue(`in createCampaign service ::`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");

        //let _data = this._getAssociateData(payload?.associateData);
        //log.blue(`in createCampaign service after formatting ::`, _data);
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: ASSOCIATE_CAMPAIGN,
                    variables: {
                        campaignId: payload?.campaignId,
                        products: payload?.associateData,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.associateProductsToCampaign)
                    return { ...res?.data?.associateProductsToCampaign };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateCampaignStatus(payload: any) {
        log.blue(`in createCampaign service ::`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");

        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPDATE_CAMPAIGN_STATUS,
                    variables: {
                        campaignId: payload?.campaignId,
                        status: payload?.status,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.editCampaignStatus)
                    return { ...res?.data?.editCampaignStatus };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getCampaignDetail(payload: any) {
        log.blue(`in createCampaign service ::`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");

        try {
            if (client) {
                const res = await client.query({
                    query: GET_CAMPAIGN_DETAIL,
                    variables: {
                        campaignId: payload?.campaignId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getCampaign)
                    return { ...res?.data?.getCampaign };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadFile(payload: any) {
        log.info(`payload in uploadFiel service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_FILE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.uploadFile) {
                    return { image: res?.data?.uploadFile };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
