import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { INVITE_NEW_USER } from "../pages/AddNewUser/graphql/add-new-user.mutation";
import { USERS_TABLE } from "../pages/UsersTable/graphql/users-table.query";
import { RE_INVITE_USER } from "../pages/UsersTable/graphql/invite-user.mutation";
import { data } from "../pages/UsersProfileDescription/config/data";
import { FIELDS, FORM_DATA } from "../pages/AddNewUser/config/formdata";
import { tableData } from "../pages/UsersTable/config/table";
import { GET_FORM } from "../pages/AddNewUser/graphql/get-form.query";
import { USER_UPDATE } from "../pages/UsersTable/graphql/activate-deactivate.mutation";
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "users.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class UsersService {
    name = SERVICE_NAME;
    private static instance: UsersService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new UsersService();
        }
        return this.instance;
    }

    async getUsersProfileDescription() {
        // const graphqlModule = getGraphQLModule();
        // const client = graphqlModule.getClient("graphql-gateway");
        // try {
        //     if (client) {
        //         const res = await client.query({
        //             query: GET_ANALYTICS,
        //             variables: {},
        //             fetchPolicy: "network-only",
        //         });
        //         return {
        //             analytics: this._getAnalytics(res?.data?.getAnalytics),
        //         };
        //     }
        // } catch (err: any) {
        //     err?.graphQLErrors &&
        //         Array.isArray(err?.graphQLErrors) &&
        //         err?.graphQLErrors.length > 0 &&
        //         toast.error(err?.graphQLErrors[0]?.message);
        // }
        return data;
    }

    async getUserTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: USERS_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            ...filter
                        }
                    },
                    fetchPolicy: "network-only",
                });
                if(res?.data?.userTable)
                return res?.data?.userTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async inviteUser(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: RE_INVITE_USER,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.resendAdminInvitation) {
                    return res?.data?.resendAdminInvitation;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateUser(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: USER_UPDATE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.editUser) {
                    return res?.data?.editUser;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getFormFields = (data: any) => {
        log.info(`roles in service`, data);
        let _initialValues = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.forEach((item: any, index: number) => {
                _initialValues.push({
                    label: item?.name,
                    value: item?._id,
                });
            });

        let res =
            FORM_DATA &&
            FORM_DATA.map((item: any, index: number) => {
                if (item?.name === "role") {
                    return {
                        ...item,
                        fieldProps: {
                            ...item?.fieldProps,
                            initialOptions: _initialValues
                                ? _initialValues
                                : [],
                        },
                    };
                } else {
                    return {
                        ...item,
                    };
                }
            });

        return res;
    };

    async getAddNewUser() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_FORM,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getRoles) {
                    return {
                        FORM_DATA: this._getFormFields(res?.data?.getRoles),
                        FIELDS,
                    };
                }
            }
        } catch (err) {}
    }

    async addNewUser(data: any) {
        log.blue(SERVICE_NAME, ": data : ", data);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: INVITE_NEW_USER,
                    variables: {
                        user: {
                            email: data?.email,
                            givenName: data?.givenName,
                            familyName: data?.familyName,
                            role:
                                data?.role &&
                                Array.isArray(data?.role) &&
                                data?.role.length > 0 &&
                                data?.role[0]?.value,
                        },
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.inviteUserByAdmin) {
                    return res?.data?.inviteUserByAdmin;
                }
            }
        } catch (err: any) {
            log.blue(`error in service: `, err?.graphQLErrors);
            getErrorMessage(err);
        }
    }

    

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
