import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { GET_PRODUCTS } from "../pages/AdminViewBatchLevelQR/graphql/getProducts.query";
import { GET_GTINS } from "../pages/AdminViewBatchLevelQR/graphql/getGtins.query";
import {
    FIELDS,
    FORM_FIELDS,
} from "../pages/AdminViewBatchLevelQR/config/formdata";
import { GET_ADMIN_QR_TABLE } from "../pages/AdminViewBatchLevelQR/graphql/getTable.query";
import { GET_TABLE_ENTRY } from "../pages/AdminViewBatchLevelQR/graphql/getTableEntry.query";
import { UPDATE_ASSOCIATE_BATCH } from "../pages/AdminViewBatchLevelQR/graphql/updateAssociateBatch.mutation";
import moment from "moment";
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "admin-batch-level-qr.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class AdminBatchLevelQRService {
    name = SERVICE_NAME;
    private static instance: AdminBatchLevelQRService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new AdminBatchLevelQRService();
        }
        return this.instance;
    }

    _getFormFields = (data: any) => {
        let _products: any[] = [];
        _products =
            data?.getProducts?.data &&
            Array.isArray(data?.getProducts?.data) &&
            data?.getProducts?.data.length > 0 &&
            data?.getProducts?.data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });

        let result = FORM_FIELDS.map((item, index) => {
            if (item?.name === "productId") {
                return {
                    ...item,
                    fieldProps: {
                        ...item?.fieldProps,
                        initialOptions: _products,
                    },
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        console.log(`_products :`, _products);
        return result;
    };

    async getProducts() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProducts)
                    return {
                        fields: FIELDS,
                        formFields: this._getFormFields(res?.data),
                    };
            }
        } catch (err: any) {
            getErrorMessage(err)
            // err?.graphQLErrors &&
            //     Array.isArray(err?.graphQLErrors) &&
            //     err?.graphQLErrors.length > 0 &&
            //     toast.error(err?.graphQLErrors[0]?.message);
        }
    }

    _getGtins = (data: any) => {
        let _gtins: any[] = [];
        _gtins =
            data?.getGtinsByProductID &&
            Array.isArray(data?.getGtinsByProductID) &&
            data?.getGtinsByProductID.length > 0 &&
            data?.getGtinsByProductID.map((item: any, index: number) => {
                return {
                    label: item?.gtinKey,
                    value: item?._id,
                };
            });
        console.log(`_gtins :`, _gtins);
        return _gtins;
    };

    async getGtins(productId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return { gtins: res?.data?.getGtinsByProductID };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getAdminTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter)
        let _date = filter?.date ?? [];
        let startDate = _date[0];
        let endDate = _date[1];
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf('day')
        startDate = startDate && moment(startDate).add('days', 1)
        startDate = startDate && moment(startDate).subtract(330, 'minute')
        startDate = startDate && moment(startDate).utc().format()
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf('day')
        endDate = endDate && moment(endDate).add('days', 1)
        endDate = endDate && moment(endDate).subtract(330, 'minute')
        endDate = endDate && moment(endDate).utc().format()
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);

        try {
            if (client) {
                const res = await client.query({
                    query: GET_ADMIN_QR_TABLE,
                    variables: {
                        criteria: {
                            product: filter?.product ?? null,
                            batch: filter?.batch ?? null,
                            createdBy: filter?.createdBy ?? null,
                            startDate : filter?.date && Array.isArray(filter?.date) && filter?.date[0] !== ""  ? startDate : null,
                            endDate: filter?.date && Array.isArray(filter?.date) && filter?.date[1] !== "" ? endDate : null,
                            download: filter?.download ?? false
                        },
                        skip: skip,
                        limit: limit
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.batchLazyBindingParentTable) {
                    const _data = res?.data?.batchLazyBindingParentTable?.data;
                    return {
                        ...res?.data?.batchLazyBindingParentTable,
                        columnDefs: _data?.columnDefs,
                        rowData: _data?.rowData,
                        fields: FIELDS,
                        formFields: this._getFormFields(res?.data),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err)
        }
    }

    async getAdminTableEntry(payload: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_TABLE_ENTRY,
                    variables: {
                        batchLazyBindingParentId: payload
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getBatchLazyBindingParent) {
                    const _data = res?.data?.getBatchLazyBindingParent;
                    return {
                        ..._data
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err)
        }
    }

    async updateAssociateBatch(payload: any) {
        log.info(`payload in updateAssociateBatch service`, payload)
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPDATE_ASSOCIATE_BATCH,
                    variables: {
                        batchLazyBindingParentId: payload?.batchLazyBindingParentId,
                        associate: {
                            batchId: payload?.batchId,
                            gtinId: payload?.gtinId,
                            productId: payload?.productId
                        }
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.associateBatchLazyBindingParentWithProduct) {
                    const _data = res?.data?.associateBatchLazyBindingParentWithProduct;
                    return {
                        ..._data
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err)
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
