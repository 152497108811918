export const QR_type_form = [
    {
        label: "Create",
        name: "qrType",
        type: "radio-button",
        fieldProps: {
            // isMulti: false,
            values: [
                {
                    label: "Single QR/NFC for entire Product",
                    value: "qrProduct",
                },
                {
                    label: "Single QR/NFC for a Batch of the Product",
                    value: "qrBatch",
                },
                {
                    label: "Individual QR/NFC per instance of Product",
                    value: "qrIndividual",
                },
            ],
        },
    },
];

export const QR_type_form_fields: any = {
    qrType: {
        validators: {
            notEmpty: {
                message: "Option is required",
            },
        },
    },
};

export const QR_FIELDS: any = {
    productId: {
        validators: {
            notEmpty: {
                message: "Product is required",
            },
        },
    },
    gtinId: {
        validators: {
            notEmpty: {
                message: "GTIN is required",
            },
        },
    },
    batchId: {
        validators: {
            notEmpty: {
                message: "Excise batch number is required",
            },
        },
    },
};

export const QR_FORM_FIELDS = [
    {
        label: "Select Product",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select GTIN",
        name: "gtinId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select Excise Batch",
        name: "batchId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
];

export const CREATE_FORM_DATA = [
    {
        label: "How Many Items you would like to Track (You can generate upto 50000 unique identifiers at a time)",
        name: "maxItems",
        type: "number-input",
        fieldProps: {},
    },
    {
        label: "Description (eg : First quarter items)",
        name: "description",
        type: "text-area",
        fieldProps: {},
    },
];

export const CREATE_FIELDS: any = {
    count: {
        validators: {
            notEmpty: {
                message: "maxItems is required",
            },
        },
    },
};
