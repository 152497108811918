import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { Persona } from "../pages/Personas/config/default-blueprint.json";
import { Persona as PersonaTalbe } from "../pages/PersonasTable/config/default-blueprint.json";
import { GET_PERSONAS } from "../pages/Personas/graphql/getPersonas.query";
import { sentenceCase } from "change-case";
import { UPDATE_PERSONAS } from "../pages/Personas/graphql/updatePersona.mutation";
import { GET_PERSONAS_TABLE } from "../pages/PersonasTable/graphql/getPersonasTable.query";
import { FIELDS, FORM_DATA } from "../pages/AddNewPersona/config/formdata";
import { GET_FORM } from "../pages/AddNewPersona/graphql/get-form.query";
import { ADD_NEW_PERSON_TO_PERSONA } from "../pages/PersonasTable/graphql/add-new-persona.mutation";
import { REMOVE_PERSON_TO_PERSONA } from "../pages/AddNewPersona/graphql/remove-persona.mutation";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "personas.service";
const log: any = LoggerModule.getInstance();
const is = require("is_js");

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class PersonaService {
    name = SERVICE_NAME;
    private static instance: PersonaService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new PersonaService();
        }
        return this.instance;
    }

    _getSectors = (data: any) => {
        let _sectors = [];
        data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                if (item?.name)
                    _sectors.push({
                        title: item?.name,
                        id: item?.sdrPersonaTx?._id,
                    });
            });
        return _sectors;
    };

    _getTemplates = (data: any) => {
        let _templates: any = {};
        if (data && is.array(data) && is.not.empty(data)) {
            data.forEach((item: any, index: number) => {
                _templates[item?.name] = [];
                if (
                    item?.sdrPersonaTx?.allCredClaims &&
                    is.array(item?.sdrPersonaTx?.allCredClaims)
                ) {
                    for (
                        let i = 0;
                        i < item?.sdrPersonaTx?.allCredClaims.length;
                        ++i
                    ) {
                        const _claim = item?.sdrPersonaTx?.allCredClaims[i];
                        if (_claim && _claim?.claim?.credentialTemplate?.name) {
                            _templates[item?.name].push({
                                credName: sentenceCase(
                                    _claim?.claim?.credentialTemplate?.name
                                ),
                                credTempId:
                                    _claim?.claim?.credentialTemplate?._id,
                                active: _claim?.isTemplateEnabled,
                                all: _claim?.claim?.allKeysEnabled,
                                claims: _claim?.claim?.allKeys,
                            });
                        }
                    }
                }
            });
        }
        console.log("getTemplates =>", _templates);
        return _templates;
    };

    async getPersonas() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PERSONAS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res) {
                    return {
                        sectors: this._getSectors(res?.data?.getPersonas),
                        templates: this._getTemplates(res?.data?.getPersonas),
                        // data: res?.data?.getPersonas,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updatePersonas(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: UPDATE_PERSONAS,
                    variables: {
                        sdrPersonatxId: payload?.sdrPersonatxId,
                        data: { ...payload?.data },
                    },
                    fetchPolicy: "no-cache",
                });
            }
            return {
                columnDefs: PersonaTalbe?.common?.columnDefs,
                rowData: PersonaTalbe?.common?.rowData,
            };
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getTableSectors = (data: any) => {
        let _sectors = [];
        data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                if (item?.name && item?.name !== "Customer")
                    _sectors.push({
                        title: item?.name,
                    });
            });
        return _sectors;
    };

    _getTableTemplates = (data: any) => {
        let _templates: any = [];
        if (data && is.array(data) && is.not.empty(data)) {
            data.forEach((item: any, index: number) => {
                if (item?.name !== "Customer") {
                    _templates.push(item);
                }
            });
        }
        console.log("getTemplates =>", _templates);
        return _templates;
    };

    async getTablePersonas() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PERSONAS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res) {
                    return {
                        sectors: this._getTableSectors(res?.data?.getPersonas),
                        templates: this._getTableTemplates(
                            res?.data?.getPersonas
                        ),
                        // data: res?.data?.getPersonas,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getPersonasTable(
        limit: number,
        skip: number,
        filter: any,
        personaId: string
    ) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PERSONAS_TABLE,
                    variables: {
                        limit,
                        skip,
                        criteria: { personaId, ...filter },
                    },
                    fetchPolicy: "network-only",
                });
                if (res) {
                    return {
                        ...res?.data?.personaTable,
                        columnDefs: res?.data?.personaTable?.data?.columnDefs,
                        rowData: res?.data?.personaTable?.data?.rowData,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getFormFields = (data: any) => {
        log.info(`roles in service`, data);
        let _initialValues = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.forEach((item: any, index: number) => {
                if (item?.name && item?.name.toLowerCase() !== "customer")
                    _initialValues.push({
                        label: item?.name,
                        value: item?._id,
                    });
            });

        let res =
            FORM_DATA &&
            FORM_DATA.map((item: any, index: number) => {
                if (item?.name === "persona") {
                    return {
                        ...item,
                        fieldProps: {
                            ...item?.fieldProps,
                            initialOptions: _initialValues
                                ? _initialValues
                                : [],
                        },
                    };
                } else {
                    return {
                        ...item,
                    };
                }
            });

        return res;
    };

    async getAddNewPersona() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_FORM,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getPersonas) {
                    return {
                        FORM_DATA: this._getFormFields(res?.data?.getPersonas),
                        FIELDS,
                    };
                }
            }
        } catch (err) {}
    }

    async addNewUser(data: any) {
        log.blue(SERVICE_NAME, ": data : ", data);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: ADD_NEW_PERSON_TO_PERSONA,
                    variables: {
                        personaId:
                            data?.persona &&
                            Array.isArray(data?.persona) &&
                            data?.persona.length > 0 &&
                            data?.persona[0]?.value
                                ? data?.persona[0]?.value
                                : "",
                        users: [
                            {
                                email: data?.email,
                                name: data?.name,
                            },
                        ],
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.addUsersToPersona) {
                    return res?.data?.addUsersToPersona;
                }
            }
        } catch (err: any) {
            log.blue(`error in service: `, err?.graphQLErrors);
            getErrorMessage(err);
        }
    }

    async removeUser(data: any) {
        log.blue(SERVICE_NAME, ": data : ", data);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: REMOVE_PERSON_TO_PERSONA,
                    variables: {
                        personaId: data?.personaId,
                        users: [
                            {
                                email: data?.email,
                                name: data?.originalName,
                                _id: data?._id,
                            },
                        ],
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.removeUsersFromPersona) {
                    return res?.data?.removeUsersFromPersona;
                }
            }
        } catch (err: any) {
            log.blue(`error in service: `, err?.graphQLErrors);
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
