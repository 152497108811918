export const associate_provenance_form = [
    {
        label: "Provenance Name*",
        name: "provName",
        type: "text-input",
        fieldProps: {
            defaultValue: '',
            disabled: true,
        },
    },
    {
        label: "Select Product*",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select Barcode*",
        name: "gtinId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select Batch*",
        name: "batchId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    }
    
];

export const associate_provenance_form_fields = {
    productId: {
        validators: {
            notEmpty: {
                message: "Product need to be selected",
            },
        },
    },
    gtinId: {
        validators: {
            notEmpty: {
                message: "Barcode is required",
            },
        },
    },
    batchId: {
        validators: {
            notEmpty: {
                message: "Batch is required",
            },
        },
    },
};