import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { CREATE_PRODUCT } from "../pages/CreateCollectables/graphql/create-product.mutation";
import { CREATE_GTIN } from "../pages/CreateCollectables/graphql/create-gtin.mutation";
import { CREATE_BATCH } from "../pages/CreateCollectables/graphql/create-batch.mutation";
import { GET_PRODUCTS } from "../pages/CreateCollectables/graphql/getProducts.query";
import { GET_GTINS } from "../pages/CreateCollectables/graphql/getGtins.query";
import {
    gtin_form,
    gtin_form_fields,
    product_form,
    product_form_fields,
    batch_form,
    batch_form_fields,
    provenance_form,
    provenance_form_fields,
    associate_provenance_form,
    associate_provenance_form_fields,
} from "../pages/CreateCollectables/config/formdata";
import { UPLOAD_PRODUCT } from "../pages/UploadCollection/graphql/uploadPorduct.mutation";
import { UPLOAD_GTIN } from "../pages/UploadCollection/graphql/uploadGtin.mutation";
import { UPLOAD_BATCH } from "../pages/UploadCollection/graphql/uploadBatch.mutation";
import { GET_DT_PRODUCTS } from "../pages/DownloadTemplate/graphql/getProducts.query";
import { dynamicData } from "../widgets/Flinstone/libs/DynamicFields.lib";
import { v4 as uuidv4 } from "uuid";
import { TEMPLATE_DATA } from "../pages/CreateCollectables/config/default-blueprint.json";
import { CREATE_PROVENANCE } from "../pages/CreateCollectables/graphql/create-provenance.mutation";
import { ASSOCIATE_PROVENANCE } from "../pages/CreateCollectables/graphql/associate.mutation";
import { CHECK_BATCH } from "../pages/CreateCollectables/graphql/checkBatch.query";
import { UPLOAD_FILE } from "../pages/CreateCollectables/graphql/upload-image.mutation";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "createCollection.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class CollectionService {
    name = SERVICE_NAME;
    private static instance: CollectionService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new CollectionService();
        }
        return this.instance;
    }

    async createProductCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_PRODUCT,
                    variables: {
                        name: payload?.name,
                        desc: payload?.desc,
                        attrs: payload?.attrs,
                        assets: {
                            imgs: payload?.imgs,
                            videos: payload?.videos,
                        },
                        // brand: "614adb9996cce558bc4a6e1b",
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.createProduct;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createGtinCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_GTIN,
                    variables: {
                        name: payload?.name,
                        desc: payload?.desc,
                        attrs: payload?.attrs,
                        assets: {
                            imgs: payload?.imgs,
                        },
                        item: payload?.productId,
                        gtinKey: payload?.gtinKey,
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.createGtin;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createBatchCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_BATCH,
                    variables: {
                        where: {
                            productId: payload?.productId,
                        },
                        data: {
                            name: payload?.name,
                            description: payload?.description,
                            variants: [payload?.gtinId],
                            //    creatorUser: "6193941de1204414dfad500f"
                        },
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.createBatch;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getGtinFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        const _arr = [
            {
                key: "quantity",
                value: "",
            },
            {
                key: "ingredients",
                value: "",
            },
            {
                key: "instructions",
                value: "",
            },
        ];
        let _arr2: any = [];
        _arr &&
            _arr.map((item: any, index: number) => {
                if (
                    item?.key.toLowerCase() !== "name" &&
                    item?.key.toLowerCase() !== "updated at"
                )
                    _arr2.push({
                        ...item,
                        id: uuidv4(),
                    });
            });
        dynamicData.update(_arr2);
        if (_data) {
            let formFields =
                gtin_form &&
                gtin_form.map((item: any, index: number) => {
                    if (item?.name === "productId") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                initialOptions: _data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    _getBatchFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        if (_data) {
            let formFields =
                batch_form &&
                batch_form.map((item: any, index: number) => {
                    if (item?.name === "productId") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                initialOptions: _data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    _getProvenanceFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                if (item?.type === "Batch") {
                    return {
                        label: `${item?.name} (Batch Level)`,
                        value: item?._id,
                    };
                } else if (item?.type === "Product") {
                    return {
                        label: `${item?.name} (Product Level)`,
                        value: item?._id,
                    };
                }
            });
        if (_data) {
            let formFields =
                provenance_form &&
                provenance_form.map((item: any, index: number) => {
                    if (item?.name === "template") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                initialOptions: _data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    _getAssocaiteFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        if (_data) {
            let formFields =
                associate_provenance_form &&
                associate_provenance_form.map((item: any, index: number) => {
                    if (item?.name === "productId") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                initialOptions: _data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    async getGtinForm(id: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProducts) {
                    if (id === "product") {
                        return {
                            fields: product_form_fields,
                            formFields: product_form,
                        };
                    } else if (id === "gtin") {
                        return {
                            fields: gtin_form_fields,
                            formFields: this._getGtinFormFields(
                                res?.data?.getProducts?.data
                            ),
                        };
                    } else if (id === "batch") {
                        return {
                            fields: batch_form_fields,
                            formFields: this._getBatchFormFields(
                                res?.data?.getProducts?.data
                            ),
                        };
                    } else if (id === "provenance") {
                        return {
                            fields: provenance_form_fields,
                            formFields: this._getProvenanceFormFields(
                                res?.data?.getProvTemplates
                            ),
                            associateFields: associate_provenance_form_fields,
                            associateFormFields: this._getAssocaiteFormFields(
                                res?.data?.getProducts?.data
                            ),
                            templates: res?.data?.getProvTemplates,
                        };
                    }
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getGtins(id: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId: id,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return { gtins: res?.data?.getGtinsByProductID };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadProductCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_PRODUCT,
                    variables: {
                        file: payload,
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.uploadProducts;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadGtinCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_GTIN,
                    variables: {
                        file: payload,
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.uploadGtins;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadBatchCollection(payload: any) {
        log.blue(`upload data in service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_BATCH,
                    variables: {
                        file: payload,
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.uploadBatches;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getDownloadTemplateProduct = (data: any) => {
        let _products = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.forEach((item: any, index: number) => {
                _products.push({
                    label: item?.name,
                    value: item?._id,
                });
            });

        return _products;
    };

    async getProducts() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_DT_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProducts?.data) {
                    return {
                        products: this._getDownloadTemplateProduct(
                            res?.data?.getProducts?.data
                        ),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getBatchGtins(id: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId: id,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return {
                        gtins: this._getDownloadTemplateProduct(
                            res?.data?.getGtinsByProductID
                        ),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createProvenance(payload: any) {
        log.success("in create provenance service:", payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                log.blue(`in provenance create service payload bla bla :: :`, payload);
                const res = await client.mutate({
                    mutation: CREATE_PROVENANCE,
                    variables: {
                        name: payload?.name,
                        provSteps: payload?.provSteps,
                        provenanceTemplate: payload?.provenanceTemplate
                    },
                    fetchPolicy: "no-cache",
                });
                log.info(`data in createProvenance::`, res);
                if (res?.data?.createProvenance) {
                    return {...res?.data?.createProvenance};
                }
            }
        } catch (err: any) {
            log.blue(`error in createProvenance :`, err?.target);
            log.blue(`error in gQL :`, err?.graphQLErrors);
            getErrorMessage(err);
        }
    }

    async associateProvenance(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                log.blue(`in provenance create service payload :`, payload);
                const res = await client.mutate({
                    mutation: ASSOCIATE_PROVENANCE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.updateProvenance) {
                    return res?.data?.updateProvenance;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async checkProvBatch(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: CHECK_BATCH,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.checkProvForDefaultBatch) {
                    return res?.data?.checkProvForDefaultBatch;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async uploadFile(payload: any) {
        log.info(`payload in uploadFiel service:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPLOAD_FILE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.uploadFile) {
                    return { image: res?.data?.uploadFile };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
