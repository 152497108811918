import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { DATA } from "../pages/Roles/config/default-blueprint";
import { sentenceCase } from "change-case";
import { GET_ROLES } from "../pages/Roles/graphql/get-roles.query";
import { UPDATE_ROLES } from "../pages/Roles/graphql/update-roles.mutation";
import { toast } from "react-hot-toast";
import { GET_PERMISSIONS } from "../pages/CreatePersonaRole/graphql/get-permissions.query";
import { CREATE_ROLE } from "../pages/CreatePersonaRole/graphql/create-role.mutation";
import {getErrorMessage} from "../utils/error.message";

const is = require("is_js");

const SERVICE_NAME = "analytics.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class RolesService {
    name = SERVICE_NAME;
    private static instance: RolesService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new RolesService();
        }
        return this.instance;
    }

    _getSectors = (data: any) => {
        let _sectors: any[] = [];
        data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                if (item?.name)
                    _sectors.push({
                        title: item?.name,
                    });
            });
        return _sectors;
    };

    _getActions(selected: any, data: any) {
        let result: any[] = [];
        data &&
            Array.isArray(data) &&
            data.map((item: any, index: number) => {
                if (item === selected) {
                    result.push({
                        key: item,
                        isEnabled: true,
                    });
                } else {
                    result.push({
                        key: item,
                        isEnabled: false,
                    });
                }
            });
        return result;
    }

    _getTemplates = (data: any, subjects: any, actions: any) => {
        let _templates: any = {};
        if (data && is.array(data) && is.not.empty(data)) {
            data.forEach((item: any, index: number) => {
                _templates[item?.name] = [];
                subjects &&
                    Array.isArray(subjects) &&
                    subjects.forEach((sub: any, subIndex: number) => {
                        let count = 0;
                        item?.permissions &&
                            Array.isArray(item?.permissions) &&
                            item?.permissions.forEach(
                                (per: any, perIndex: number) => {
                                    if (per?.subject === sub) {
                                        log.info(
                                            `getT sub mathced: ${item?.name}`,
                                            sub
                                        );
                                        count = count + 1;
                                        _templates[item?.name].push({
                                            active: true,
                                            credName: sub,
                                            claims: this._getActions(
                                                per?.action,
                                                actions
                                            ),
                                            sector: item?.name,
                                        });
                                    }
                                }
                            );
                        if (count === 0) {
                            _templates[item?.name].push({
                                active: false,
                                credName: sub,
                                claims: this._getActions(null, actions),
                                sector: item?.name,
                            });
                        } else {
                            // _templates[item?.name] &&
                            //     Array.isArray(_templates[item?.name]) &&
                            //     _templates[item?.name].forEach(
                            //         (temp: any, tempIndex: number) => {
                            //             if(temp?.credName === sub){
                            //                 return;
                            //             }
                            //         }
                            //     );
                        }
                    });
            });
        }
        console.log("getTemplates =>", _templates);
        return _templates;
    };

    _getExistingCreds(data: any) {
        let _data: any[] = [];
        data &&
            Array.isArray(data) &&
            data.forEach((item: any, index: number) => {});
    }

    async getRoles() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_ROLES,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                log.info("role results: ", res?.data?.getRoles);
                if (res?.data?.getRoles) {
                    let sectors = this._getSectors(res?.data?.getRoles);
                    let templates = this._getTemplates(
                        res?.data?.getRoles,
                        res?.data?.getRolePermissionMeta?.subjects,
                        res?.data?.getRolePermissionMeta?.actions
                    );
                    let _existingCreds = this._getExistingCreds(
                        res?.data?.getRoles
                    );
                    //let templates =  this._getTemplates(DATA);
                    return {
                        sectors,
                        templates,
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async updateRoles(payload: any) {
        log.blue(`payload in updateRole service`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: UPDATE_ROLES,
                    variables: {
                        updatePermissionsRole: payload?.rollName,
                        updatePermissionsPermissions: payload?.permissions,
                    },
                    fetchPolicy: "no-cache",
                });
                log.info("role results: ", res?.data?.updatePermissions);
                return res?.data?.updatePermissions;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getPermissionsActions(data: any) {
        let result: any[] = [];
        data &&
            Array.isArray(data) &&
            data.map((item: any, index: number) => {
                result.push({
                    key: item,
                    isEnabled: false,
                });
            });
        return result;
    }

    _getPermissionsTemplates(subjects: any, actions: any) {
        let _templates: any = [];
        subjects &&
            Array.isArray(subjects) &&
            subjects.forEach((item: any, index: any) => {
                _templates.push({
                    credName: item,
                    active: false,
                    all: false,
                    claims: this._getPermissionsActions(actions),
                });
            });
        return _templates;
    }

    async getPermissions() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PERMISSIONS,
                    variables: {},
                    fetchPolicy: "network-only",
                });

                let formFields = [
                    {
                        label: "Role Name",
                        name: "roleName",
                        type: "text-input",
                        fieldProps: {},
                    },
                ];
                log.info(`bla result:`, res?.data);
                let templates = this._getPermissionsTemplates(
                    res?.data?.getRolePermissionMeta?.subjects,
                    res?.data?.getRolePermissionMeta?.actions
                );
                log.info(`bla templates:`, templates);
                return {
                    formFields,
                    subjects: res?.data?.getRolePermissionMeta?.subjects,
                    actions: res?.data?.getRolePermissionMeta?.actions,
                    templates,
                };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createRole(payload: any) {
        log.blue(SERVICE_NAME, ": payload : ", payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_ROLE,
                    variables: {
                        createRoleName: payload?.roleName,
                        createRolePermissions: payload?.permissions,
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.createRole;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
