export const data = {
  img:
    "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/vlinder-logo.jpeg",
  title: "vlinder Labs Private Limited",
  description:
    "vlinder is a startup with a vision to enable businesses to harness the power of Blockchain through its state of the art and innovative platforms",
  meta: [
    {
      key: "Owner",
      value: "developer@vlinder.io",
    },
    {
      key: "Address",
      value:
        "TERRACE FLOOR, 837, 11TH CROSS, 26th Main Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka - 560102",
    },
    {
      key: "Phone",
      value: "+91 99123 45678",
    },
    {
      key: "Website",
      value: "https://www.vlinder.io",
    },
    {
      key: "Email",
      value: "info@vlinder.io",
    },
  ],
  // buttons: [
  //   {
  //     key: "edit",
  //     title: "Edit",
  //   },
  // ],
};
