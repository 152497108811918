import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { data } from "../pages/NetworkOperator/config/data";
import { GET_MANUFACTURER } from "../pages/NetworkOperator/graphql/getManufaturer.query";
import { humanize } from "uno-js";
import { getErrorMessage } from "../utils/error.message";
import { EDIT_NETWORK_OPERATOR } from "../pages/SocialMediaHandles/graphql/editNetworkOperator.mutation";

const SERVICE_NAME = "network-operator.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export const SOCIAL = [
    {
        type: "linkedin",
        link: "https://in.linkedin.com/company/vlinder-io",
    },
    {
        type: "twitter",
        link: "https://twitter.com/govlinder?lang=en",
    },
    {
        type: "instagram",
        link: "https://www.instagram.com/vlinder.io/?hl=en",
    },
    {
        type: "facebook",
        link: "https://www.facebook.com/vlinder.labs.56",
    },
    {
        type: "email",
        link: "mailto:info@vlinder.io",
    },
    {
        type: "phone",
        link: "tel:919844981710",
    },
];

export class NetworkOperatorService {
    name = SERVICE_NAME;
    private static instance: NetworkOperatorService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new NetworkOperatorService();
        }
        return this.instance;
    }

    getMeta = (data: any) => {
        if (data) {
            let _output: any = [];

            for (const [key, value] of Object.entries(data)) {
                if (
                    key === "fullAddress" ||
                    key === "email" ||
                    key === "telephone" ||
                    key === "website" ||
                    key === "owner"
                ) {
                    _output.push({
                        key: humanize(key.toString()),
                        value: value,
                    });
                }
                // else if (key === "sameAs") {
                //     _output.push({
                //         key: humanize("website"),
                //         value: value,
                //     });
                // }
            }
            return _output;
        }
    };

    _getSocialMediaHandles = (socialHandles: any) => {
        let _res: any[] = [];
        socialHandles?.socialLinks &&
            Array.isArray(socialHandles?.socialLinks) &&
            socialHandles?.socialLinks.length > 0 &&
            socialHandles?.socialLinks.map((item: any, index: number) => {
                _res.push({
                    type: item?.type,
                    link: item?.url,
                });
            });

        return _res;
    };

    async getNetworkOperator() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_MANUFACTURER,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getNetworkOperatorOrganization) {
                    return {
                        organizationData:
                            res?.data?.getNetworkOperatorOrganization,
                        img: res?.data?.getNetworkOperatorOrganization?.logo,
                        title: res?.data?.getNetworkOperatorOrganization?.name,
                        description:
                            res?.data?.getNetworkOperatorOrganization
                                ?.description,
                        key: res?.data?.getNetworkOperatorOrganization
                            ?.fullAddress,
                        meta: this.getMeta(
                            res?.data?.getNetworkOperatorOrganization
                        ),
                        social: this._getSocialMediaHandles(
                            res?.data?.getNetworkOperatorOrganization
                        ),
                    };
                }
            }
            return data;
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async editNetworkOperator(payload: any) {
        log.blue(`edit payload in NetworkOperator service :`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: EDIT_NETWORK_OPERATOR,
                    variables: { ...payload },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.editMyOrganization) {
                    return {
                        ...res?.data?.editMyOrganization,
                    };
                }
            }
            return data;
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
