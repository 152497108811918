import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
// import { tableData } from "../pages/QRBatches/config/table";
// import { tableData as data } from "../pages/QRRanges/config/table";
import { GET_QR_BATCHES } from "../pages/AssortmentBatches/graphql/getBatchTable.query";
import { GET_ASSORTMENT_RANGE } from "../pages/AssortmentRanges/graphql/getRangeTable.query";
import {
    FIELDS,
    FORM_DATA,
} from "../pages/NewAssortmentBatches/config/formdata";
import {
    FIELDS as fields,
    FORM_DATA as formData,
} from "../pages/NewAssortmentRanges/config/formdata";
import { GET_PRODUCTS } from "../pages/NewAssortmentRanges/graphql/getProducts.query";
import { GET_GTINS } from "../pages/NewAssortmentRanges/graphql/getGtins.query";
import { CREATE_ASSORTMENT_BATCH } from "../pages/NewAssortmentBatches/graphql/createQrBatch.mutation";
import { CREATE_ASSORTMENT_RANGE } from "../pages/NewAssortmentRanges/graphql/createQrRange.mutation";
import { GENERATE_CSV } from "../pages/AssortmentBatches/graphql/generateCsv.query";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "QR.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class QRService {
    name = SERVICE_NAME;
    private static instance: QRService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new QRService();
        }
        return this.instance;
    }

    async getQrBatches(batchId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_QR_BATCHES,
                    variables: {
                        skip: 0,
                        limit: 50000,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.serializationGroupTable)
                    return {
                        columnDefs:
                            res?.data?.serializationGroupTable?.data
                                ?.columnDefs,
                        rowData:
                            res?.data?.serializationGroupTable?.data?.rowData,
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getQrRanges() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_ASSORTMENT_RANGE,
                    variables: {
                        skip: 0,
                        limit: 50000,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.entityRangeTable)
                    return {
                        columnDefs:
                            res?.data?.entityRangeTable?.data?.columnDefs,
                        rowData: res?.data?.entityRangeTable?.data?.rowData,
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getQrBatch() {
        const graphqlModule = getGraphQLModule();
        //const client = graphqlModule.getClient("graphql-gateway");
        try {
            // if (client) {
            //     const res = await client.query({
            //         query: GET_QR_RANGE,
            //         variables: {},
            //         fetchPolicy: "network-only",
            //     });
            //     if (res?.data?.qrRangeTable)
            //         return {
            //             columnDefs: res?.data?.qrRangeTable?.data?.columnDefs,
            //             rowData: res?.data?.qrRangeTable?.data?.rowData,
            //         };
            // }
            return { FIELDS, FORM_DATA };
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createQrBatch(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_ASSORTMENT_BATCH,
                    variables: {
                        data: payload?.data,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.createSerializationGroup)
                    return res?.data?.createSerializationGroup;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getFormData = (data: any) => {
        let _products: any[] = [];
        let _qrBatches: any[] = [];
        _products =
            data?.getProducts?.data &&
            Array.isArray(data?.getProducts?.data) &&
            data?.getProducts?.data.length > 0 &&
            data?.getProducts?.data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        _qrBatches =
            data?.getSerializationGroups &&
            Array.isArray(data?.getSerializationGroups) &&
            data?.getSerializationGroups.length > 0 &&
            data?.getSerializationGroups.map((item: any, index: number) => {
                return {
                    label: `${item?.batchNo} ${item?.description}`,
                    value: item?._id,
                };
            });
        log.blue(`_qrBatches:`, _qrBatches);
        let result = formData.map((item, index) => {
            if (item?.name === "qrBatchId") {
                return {
                    ...item,
                    fieldProps: {
                        ...item?.fieldProps,
                        initialOptions: _qrBatches,
                    },
                };
            } else if (item?.name === "productId") {
                return {
                    ...item,
                    fieldProps: {
                        ...item?.fieldProps,
                        initialOptions: _products,
                    },
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        return result;
    };

    async getQrRangeForm() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PRODUCTS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (
                    res?.data?.getProducts &&
                    res?.data?.getSerializationGroups
                ) {
                    return {
                        FIELDS: fields,
                        FORM_DATA: this._getFormData(res?.data),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async createQrRange(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: CREATE_ASSORTMENT_RANGE,
                    variables: {
                        data: payload?.data,
                        gtinId: payload?.gtinId,
                        productBatchId: payload?.productBatchId,
                        serializationGroupId: payload?.serializationGroupId,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.createEntityRange)
                    return res?.data?.createEntityRange;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getGtins(productId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return { gtins: res?.data?.getGtinsByProductID };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async generateCsv(payload: any) {
        console.log(`serializationGroupId:`, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GENERATE_CSV,
                    variables: {
                        serializationGroupId: payload?.serializationGroupId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getMyCSVforBatch?.serializationGroup) {
                    return { ...res?.data?.getMyCSVforBatch?.serializationGroup };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
