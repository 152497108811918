import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { GET_REPORT_TABLE } from "../pages/Report/graphql/report.query";
import moment from "moment";
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "report.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class ReportService {
    name = SERVICE_NAME;
    private static instance: ReportService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ReportService();
        }
        return this.instance;
    }

    async getReportTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.date ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf('day')
        startDate = startDate && moment(startDate).add('days', 1)
        startDate = startDate && moment(startDate).subtract(330, 'minute')
        startDate = startDate && moment(startDate).utc().format()
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf('day')
        endDate = endDate && moment(endDate).add('days', 1)
        endDate = endDate && moment(endDate).subtract(330, 'minute')
        endDate = endDate && moment(endDate).utc().format()
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: GET_REPORT_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            tableName: filter?.tableName ?? null,
                            status: filter?.status ?? null,
                            startDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.reportTable) return res?.data?.reportTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
